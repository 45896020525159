export class Signup {
    static setup() {
        if(window.location.pathname === '/auth/sign_up') {
            Signup.setFieldFromParam('#user_email', 'email');
            Signup.setFieldFromParam('#user_first_name', 'first_name');
            Signup.setFieldFromParam('#user_last_name', 'last_name');
            Signup.setFieldFromParam('#user_mobile_phone', 'mobile_phone');
        }
    }

    static setFieldFromParam(inputFieldId, urlParam) {
        let searchParams = new URLSearchParams(window.location.search);

        if(searchParams.get(urlParam)) {
            $(inputFieldId).val(searchParams.get(urlParam));
        }
    }
}
