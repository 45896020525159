export class Blogs {
    static setup() {
        $('.copy-to-clipboard').click(function() {
            navigator.clipboard.writeText($(this).data('clipboardText')).then(function() {
                alert('Image URL copied!');
            }, function() {
                alert('Image URL copied failed');
            });
        })
    }
}
