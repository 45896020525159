export class StoreLocations {
  static setup() {
    StoreLocations.toggleTopFeaturedFields();
    StoreLocations.toggleBottomFeaturedFields();
    StoreLocations.toggleCanadianOnlyFields();
    StoreLocations.toggleDeliveryRadius();

    $('#store_location_top_featured_location').change(function() {
      StoreLocations.toggleTopFeaturedFields();
    });

    $('#store_location_bottom_featured_location').change(function() {
      StoreLocations.toggleBottomFeaturedFields();
    });

    $('#store_location_state').change(function() {
      StoreLocations.toggleCanadianOnlyFields();
      StoreLocations.toggleDeliveryRadius();
    });

    $('#store_location_local_delivery').change(function() {
      StoreLocations.toggleDeliveryRadius();
    });

    $('#store_location_delivery_only').change(function() {
      StoreLocations.toggleDeliveryRadius();
    });
  }

  static toggleCanadianOnlyFields() {
    let nationWideShippingField = $('.store_location_nationwide_shipping');
    let nationWideShippingInput = $('#store_location_nationwide_shipping');
    let localDeliveryInput = $('#store_location_local_delivery');
    if(StoreLocations.isCanadian()) {
      nationWideShippingField.closest('div').show();
    } else {
      nationWideShippingInput.prop('checked', false);
      localDeliveryInput.prop('checked', false);
      nationWideShippingField.closest('div').hide();
    }
  }

  static isCanadian() {
    let country = $('#store_location_state option:selected').closest('optgroup').attr('label');
    return country === 'Canada';
  }

  static isLocalDelivery() {
    return $('#store_location_local_delivery').prop('checked');
  }

  static isDeliveryOnly() {
    return $('#store_location_delivery_only').prop('checked');
  }

  static toggleDeliveryRadius() {
    if(this.isDeliveryOnly() && (!this.isCanadian() || this.isLocalDelivery())) {
      $('.store_location_delivery_radius').show();
    } else {
      $('.store_location_delivery_radius').hide();
      $('#store_location_delivery_radius').val('');
    }
  }

  static toggleBottomFeaturedFields() {
    let val;
    val = $('#store_location_bottom_featured_location').val();
    if (val === 'store_address') {
      $('.store_location_bottom_featured_radius').show();
      $('.store_location_bottom_featured_metro_area_id').hide();
      return $('#store_location_bottom_featured_metro_area_id').val('');
    } else if (val === 'metro_area') {
      $('.store_location_bottom_featured_metro_area_id').show();
      $('.store_location_bottom_featured_radius').hide();
      return $('#store_location_bottom_featured_radius').val('');
    } else {
      $('.store_location_bottom_featured_radius').hide();
      return $('.store_location_bottom_featured_metro_area_id').hide();
    }
  };

  static toggleTopFeaturedFields() {
    let val;
    val = $('#store_location_top_featured_location').val();
    if (val === 'store_address') {
      $('.store_location_top_featured_radius').show();
      $('.store_location_top_featured_metro_area_id').hide();
      return $('#store_location_top_featured_metro_area_id').val('');
    } else if (val === 'metro_area') {
      $('.store_location_top_featured_metro_area_id').show();
      $('.store_location_top_featured_radius').hide();
      return $('#store_location_top_featured_radius').val('');
    } else {
      $('.store_location_top_featured_radius').hide();
      return $('.store_location_top_featured_metro_area_id').hide();
    }
  };
}
