import { Chart } from 'chart.js/dist/Chart.min'

export class CouponStats {
  static setup() {
      $('.lineChartCouponState').each(function() {
        CouponStats.loadCouponStateCharts(this, $(this).data('click-coupons'), $(this).data('print-coupons'), $(this).data('labels'));
      });
  }

  static loadCouponStateCharts (element, click_coupons, print_coupons, labels) {
    let data, options;
    options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true
      },
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              beginAtZero: true,
              min: 0,
              suggestedMin: 0,
              callback: function(value) {
                return value;
              }
            }
          }
        ]
      },
      hover: {
        mode: 'nearest',
        intersect: false
      },
      tooltips: {
        enabled: false,
        mode: 'nearest',
        intersect: false,
        custom: function(tooltipModel) {
          let bodyLines, getBody, innerHtml, position, titleLines, today, tooltipEl, tooltipRoot;
          tooltipEl = document.getElementById('chartjsAreaTooltip');
          getBody = function(bodyItem) {
            return bodyItem.lines;
          };
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjsAreaTooltip';
            tooltipEl.classList.add('u-chartjs-tooltip-wrap');
            tooltipEl.innerHTML = '<div class=\'u-chartjs-tooltip\'></div>';
            document.body.appendChild(tooltipEl);
          }
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }
          if (tooltipModel.body) {
            titleLines = tooltipModel.title || [];
            bodyLines = tooltipModel.body.map(getBody);
            today = new Date;
            innerHtml = '<header class=\'u-chartjs-tooltip-header\'>';
            titleLines.forEach(function(title) {
              innerHtml += title;
            });
            innerHtml += '</header><div class=\'u-chartjs-tooltip-body\'>';
            bodyLines.forEach(function(body, i) {
              let newBody, oldBody;
              oldBody = body[0];
              newBody = oldBody;
              innerHtml += newBody;
            });
            innerHtml += '</div>';
            tooltipRoot = tooltipEl.querySelector('.u-chartjs-tooltip');
            tooltipRoot.innerHTML = innerHtml;
          }
          position = this._chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) - 3 + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 25 + 'px';
          tooltipEl.style.pointerEvents = 'none';
        }
      },
      elements: {
        line: {
          borderWidth: 3
        },
        point: {
          pointStyle: 'circle',
          radius: 5,
          hoverRadius: 7,
          borderWidth: 3,
          backgroundColor: '#ffffff'
        }
      }
    };
    data = {
      labels: labels,
      datasets: [
        {
          label: 'Prints',
          data: print_coupons,
          backgroundColor: 'rgba(241,37,89, .1)',
          borderColor: 'rgba(241,37,89, 1)'
        }, {
          label: 'Clicks',
          data: click_coupons,
          backgroundColor: 'rgba(30, 130, 76, 0.1)',
          borderColor: 'rgba(30, 130, 76, 1)'
        }
      ]
    };
    new Chart(element, {
      type: 'line',
      data: data,
      options: options
    });
    $(element).addClass('initialized');
  };
}
