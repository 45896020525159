import {AdService} from "../src/ad_service";

require('chart.js/dist/Chart.min.css');
import { BatchEdit } from '../src/batch_edit';
import { Blogs } from '../src/blogs';
import { CouponStats } from '../src/coupon_stats';
import { StoreLocations } from '../src/store_locations';
import { Stores } from '../src/stores';
import { Signup } from '../src/signup';
import $ from 'jquery';

$(document).ready(function() {
  BatchEdit.setup();
  Blogs.setup();
  CouponStats.setup();
  StoreLocations.setup();
  Stores.setup();
  Signup.setup();
  AdService.setup();
});

window.$ = $;
