export class BatchEdit {
  static setup() {
    let selected_fields_prev_value;
    selected_fields_prev_value = $('#batch_coupons_selected_fields').val() || [];
    return $('#batch_coupons_selected_fields').chosen({
      allow_single_deselect: true,
      no_results_text: 'No fields found',
      width: '100%'
    }).change(function(event) {
      let removed_fields, selected_fields;
      selected_fields = $(this).val();
      if (selected_fields) {
        $('input[name="commit"]').attr('disabled', false);
        removed_fields = selected_fields_prev_value.filter(function(val) {
          return !selected_fields.includes(val);
        });
        selected_fields.forEach(function(value) {
          return $($("#batch_coupons_" + value).parents('.form-group')[0]).removeClass('d-none')
        });
      } else {
        $('input[name="commit"]').attr('disabled', true);
        removed_fields = selected_fields_prev_value;
      }
      removed_fields.forEach(function(value) {
        return $($("#batch_coupons_" + value).parents('.form-group')[0]).addClass('d-none');
      });
      return selected_fields_prev_value = selected_fields || [];
    });
  }
}
