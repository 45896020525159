

export class AdService {
    static setup() {
        if($('#ad_service_ad_zone_id').length) {
            this.maybeShowContent();
            $('#ad_service_ad_zone_id').change(function() {
                AdService.maybeShowContent();
            })
        }
    }

    static maybeShowContent() {
        let fixed_ad_zone = 'Fixed-Footer-(500*100)';

        if($('#ad_service_ad_zone_id option:selected').text() === fixed_ad_zone) {
            $('.ad_service_content').show();
        } else {
            $('.ad_service_content').val('');
            $('.ad_service_content').hide();
        }
    }
}
