export class Stores {
    static setup() {
        Stores.toggleCategoryFeaturedFields();
        $('#store_category_featured_location').change(function() {
            return Stores.toggleCategoryFeaturedFields();
        });
    }

    static toggleCategoryFeaturedFields() {
        let val;
        val = $('#store_category_featured_location').val();
        if (val === 'store_address') {
            $('.store_category_featured_radius').show();
        } else {
            $('#store_category_featured_radius').val('');
            $('.store_category_featured_radius').hide();
        }
    };
}
